<template>
  <div class="loader__container">
    <!-- <span
      class="loader"
      id="loader">Cargando...</span> -->
    <span class="bar_loader"></span>
  </div>
</template>

<script>
export default {
  props:{
    percentage:{
      type: Number,
      default: 0
    }
  },
  mounted(){
    var loader = document.getElementById('loader')
    var vm = this
    console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<Loader", loader);
    document.documentElement.style.setProperty('--loading-percentage', vm.percentage + '%')
  },
  watch:{
    percentage(){
      var vm = this
      document.documentElement.style.setProperty('--loading-percentage', vm.percentage + '%')
      console.log(vm.percentage);
    }
  }
}
</script>
