const state = {
    is_login: false,
    is_in_alert: false,
    is_qr_code: false,
    is_complete_registration: false,    
    alert_message: '',
    device_id: '',
    user_token: '',
    image_server: '',
    tags: [],
    categories: [],
    locations: [],
    offers: [],
    loader: false,
    user_id: null,
    user_type: "",
    email_user: '',
    dashboard_active: 0,
    location_time_zone: "",
    sublocation_id: null,
    booking_date: null,
    capacity_validator: false,
    user_id_encrypt: null,
    scan_devices_ips: [],
    scan_devices_sockets: null,
    user_roles: [],
    name_user: '',
    company:{
        id:'',
        user_name: '',
        description:"",
        document_number: "",
        email: "",
        company_name: "",
        address: "",
        identify_number: "",
        has_booking_locations: false,
        max_time_exceeded_blocking: false,
        dashboard:{
          users: true,
          offers: true,
          rewards: true,
          access: true
        },
        offers:{
          redemption_form:"",
          favorites: true
        },
        rewards:{
          user_characteristics: true,
          segments: true,
          rewards: true
        },
        access:{
          general_access:'',
          without_internet_access: false,
          keyboard_access: false,
          dynamic_invitations: true,
          room_bookings: '',
          courtesies: true,
          covenants: true,
          reports: true,
          alerts: true,
          counter: true,
          user_access: true
        },
        locations:{
          offers_and_rewards: true,
          sublocations: {
            time_out: false,
            bookings: true
          },
          holidays: false
        },
        personal_trainer:{
          personal_trainer: true
        },
        messages:{
          notifications:false,
          segment_mails: false
        },
        news:{
          general_news: true,
          video_news: true,
          tweets_news: true
        },
        events:{
          tickets: true
        },
        company_connections:{
          company_connections: false
        },
        client_information: {
          us: false,
          contact_us: false
        },
        personalize:{
          web_page: false,
          mobile_app: false,
          colors: {
            principal: '#0076FF',
            secundary:'#09CA6C'
          },
          app_logo: '',
          principal_sections: [],
          secondary_sections: [],
          personalize_mails: false
        },
        inside_request:{
          payment_certificate: false,
          work_certificate: false,
          contribution_certificate: false
        },
        api_connections:{
          crm: false,
          end_points: false
        }
    }
}

const getters = {
    getIsLogin(state) {
        return state.is_login
    },
    getIsInAlert(state) {
        return state.is_in_alert
    },
    getIsQrCode(state) {
        return state.is_qr_code
    },
    getIsCompleteRegistration(state) {
        return state.is_complete_registration
    },
    getAlertMessage(state) {
        return state.alert_message
    },
    getDeviceId(state) {
        return state.device_id
    },
    getUserToken(state) {
        return state.user_token
    },
    getImageServer(state) {
        return state.image_server
    },
    getTags(state) {
        return state.tags
    },
    getCategories(state) {
        return state.categories
    },
    getLocations(state) {
        return state.locations
    },
    getOffers(state) {
        return state.offers
    },
    getLoader(state) {
        return state.loader
    },
    getUserId(state) {
        return state.user_id
    },
    getUserType(state) {
        return state.user_type
    },
    getEmailUser (state) {
        return state.email_user
    },
    getNameUser (state) {
        return state.name_user
    },
    getDashboardActive(state) {
        return state.dashboard_active
    },
    getCapacityValidator(state) {
        return state.capacity_validator
    },
    getUserIdEncrypt(state){
        return state.user_id_encrypt
    },
    getCompany(state){
        return state.company
    },
    getScanDevicesIps(state){
        return state.scan_devices_ips
    },
    getScanDevicesSockets(state){
        return state.scan_devices_sockets
    },
    getUserRoles(state){
        return state.user_roles
    },
    getSublocationId(state) {
        return state.sublocation_id
    },
    getBookingDate(state) {
        return state.booking_date
    }
}

const mutations = {
    setIsLogin: function(state, payload) {
        state.is_login = payload
    },
    setIsInAlert: function(state, payload) {
        state.is_in_alert = payload
    },
    setIsQrCode: function(state, payload) {
        state.is_qr_code = payload
    },
    setIsCompleteRegistration: function(state, payload) {
        state.is_complete_registration = payload
    },
    setAlertMessage: function(state, payload) {
        state.alert_message = payload
    },
    setDeviceId(state, payload) {
        state.device_id = payload
    },
    setUserToken(state, payload) {
        state.user_token = payload
    },
    setImageServer(state, payload) {
        state.image_server = payload
    },
    setTags(state, payload) {
        state.tags = payload
    },
    setCategories(state, payload) {
        state.categories = payload
    },
    setLocations(state, payload) {
        state.locations = payload
    },
    setOffers(state, payload) {
        state.offers = payload
    },
    setLoader(state, payload) {
        state.loader = payload
    },
    setUserId(state, payload) {
        state.user_id = payload
    },
    setUserType(state, payload) {
        state.user_type = payload
    },
    setEmailUser: function (state, payload) {
        state.email_user = payload
        sessionStorage.setItem('email_user',payload)
    },
    setNameUser: function (state, payload) {
        state.name_user = payload
        sessionStorage.setItem('name_user',payload)
    },
    setDashboardActive(state, payload) {
        state.dashboard_active = payload
    },
    setCapacityValidator(state, payload) {
        state.capacity_validator = payload
    },
    setUserIdEncrypt: function (state, payload) {
        state.user_id_encrypt = payload
        sessionStorage.setItem('user_id_encrypt',payload)
    },
    setCompany(state, payload) {
        state.company = payload
    },
    setScanDevicesIps(state, payload) {
        state.scan_devices_ips = payload
    },
    setScanDevicesSockets(state, payload) {
        state.scan_devices_sockets = payload
    },
    setUserRoles(state, payload) {
        state.user_roles = payload
    },
    setSublocationId: function(state, payload) {
        state.sublocation_id = payload
        sessionStorage.setItem('sublocation_id', payload)
    },
    setBookingDate: function(state, payload) {
        state.booking_date = payload
        sessionStorage.setItem('booking_date', payload)
    }
}

const actions = {
    updateIsLogin: function({ commit }, payload) {
        commit('setIsLogin', payload)
    },
    updateIsInAlert({ commit }, payload) {
        commit('setIsInAlert', payload)
    },
    updateIsQrCode: function({ commit }, payload) {
        commit('setIsQrCode', payload)
    },
    updateIsCompleteRegistration: function({ commit }, payload) {
        commit('setIsCompleteRegistration', payload)
    },
    updateAlertMessage({ commit }, payload) {
        commit('setAlertMessage', payload)
    },
    updateDeviceId({ commit }, payload) {
        commit('setDeviceId', payload)
    },
    updateUserToken({ commit }, payload) {
        commit('setUserToken', payload)
    },
    updateImageServer({ commit }, payload) {
        commit('setImageServer', payload)
    },
    updateTags({ commit }, payload) {
        commit('setTags', payload)
    },
    updateCategories({ commit }, payload) {
        commit('setCategories', payload)
    },
    updateLocations({ commit }, payload) {
        commit('setLocations', payload)
    },
    updateAllLocations({ commit }, payload) {
        commit('setAllLocations', payload)
    },
    updateOffers({ commit }, payload) {
        commit('setOffers', payload)
    },
    updateLoader({ commit }, payload) {
        commit('setLoader', payload)
    },
    updateUserId({ commit }, payload) {
        commit('setUserId', payload)
    },
    updateUserType({ commit }, payload) {
        commit('setUserType', payload)
    },
    updateEmailUser: function ({commit}, payload) {
        commit('setEmailUser', payload)
    },
    updateNameUser: function ({commit}, payload) {
        commit('setNameUser', payload)
    },
    updateDashboardActive({ commit }, payload) {
        commit('setDashboardActive', payload)
    },
    updateCapacityValidator({ commit }, payload) {
        commit('setCapacityValidator', payload)
    },
    updateUserIdEncrypt: function ({commit}, payload) {
        commit('setUserIdEncrypt', payload)
    },
    updateCompany: function ({commit}, payload) {
        commit('setCompany', payload)
    },
    updateScanDevicesIps({commit}, payload) {
        commit('setScanDevicesIps', payload)
    },
    updateScanDevicesSockets({commit}, payload) {
        commit('setScanDevicesSockets', payload)
    },
    updateUserRoles({commit}, payload) {
        commit('setUserRoles', payload)
    },
    updateSublocationId: function({ commit }, payload) {
        commit('setSublocationId', payload)
    },
    updateBookingDate: function({ commit }, payload) {
        commit('setBookingDate', payload)
    }
}

export default {
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions
}
