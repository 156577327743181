<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="43"
    height="30" 
    viewBox="0 0 43 30">
    <path
      fill="#646a85"
      d="M36.12,15.281a6.446,6.446,0,0,0-9.6-7.661A10.757,10.757,0,0,0,6.45,12.964c0,.181.007.362.013.542A9.641,9.641,0,0,0,9.675,32.25H34.4a8.568,8.568,0,0,0,1.72-16.969Zm-9.688,4.112H22.038v7.5a1.076,1.076,0,0,1-1.075,1.071H17.737a1.076,1.076,0,0,1-1.075-1.071v-7.5H12.268a1.069,1.069,0,0,1-.759-1.828l7.082-7.058a1.081,1.081,0,0,1,1.518,0l7.082,7.058a1.07,1.07,0,0,1-.759,1.828Z"
      transform="translate(0 -2.25)"/>
  </svg>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>
