<template>
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:cc="http://web.resource.org/cc/"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:svg="http://www.w3.org/2000/svg"
    id="svg2"
    viewBox="0 0 200 131.47"
    version="1.0">
    <defs id="defs4">
      <linearGradient id="linearGradient3133">
        <stop id="stop3135" :stop-color="principal_color" offset="0"/>
        <stop id="stop3137" :stop-color="secundary_color" offset="1"/>
      </linearGradient>
      <filter id="filter3215" y="-.14327" width="1.1165" height="1.2865" x="-.058241">
        <feGaussianBlur id="feGaussianBlur3217" stdDeviation="25.126375"/>
      </filter>
      <filter id="filter3347" y="-.17316" width="1.2661" height="1.3463" x="-.13306">
        <feGaussianBlur id="feGaussianBlur3349" stdDeviation="9.0990021"/>
      </filter>
      <filter id="filter3437" y="-.11218" width="1.1712" height="1.2244" x="-.085576">
        <feGaussianBlur id="feGaussianBlur3439" stdDeviation="8.1566192"/>
      </filter>
      <linearGradient id="linearGradient2220" y2="640.43" xlink:href="#linearGradient3133" gradientUnits="userSpaceOnUse" x2="762.92" y1="354" x1="186.39"/>
      <linearGradient id="linearGradient2222" y2="276.31" xlink:href="#linearGradient3133" gradientUnits="userSpaceOnUse" x2="616.92" gradientTransform="matrix(1 0 0 -1 5.7798e-8 726.89)" y1="102.5" x1="357.14"/>
      <linearGradient id="linearGradient2224" y2="276.31" xlink:href="#linearGradient3133" gradientUnits="userSpaceOnUse" x2="616.92" y1="102.5" x1="357.14"/>
    </defs>
    <g id="layer1" transform="translate(77.477 -517.67)">
      <g id="g2199" transform="matrix(.23009 0 0 .23009 -59.65 499.79)">
        <rect
          class="envelope_background"
          id="rect2160"
          stroke-linejoin="round"
          style="color:#000000"
          rx="54.286"
          ry="54.286"
          height="565.71"
          width="862.86"
          stroke="borders_color"
          stroke-linecap="round"
          y="80.934"
          x="-74.286"
          stroke-width="5"
          fill="url(#linearGradient2220)"/>
        <path
          class="envelope_back_line"
          id="path3221"
          stroke-linejoin="round"
          style="color:#000000"
          d="m-20 645.95c-30.074 0-54.281-24.2-54.281-54.28v-41.78l399.28-244c23.35-14.27 60.93-14.27 84.28 0l379.28 231.78v54c0 30.08-24.2 54.28-54.28 54.28h-754.28z"
          :stroke="borders_color"
          stroke-linecap="round"
          stroke-width="3"
          fill="url(#linearGradient2222)"/>
        <path
          class="envelope_shadow_flap"
          id="path3169"
          filter="url(#filter3215)"
          style="color:#000000"
          d="m-27.767 157.25l-46.514 19.75 399.28 244c23.35 14.27 60.93 14.27 84.28 0l379.28-231.78-57.53-16.06c-192.51-72.42-564.38-82.43-758.8-15.91z"/>
        <path
          class="envelope_top_flap"
          id="rect3141"
          stroke-linejoin="round"
          style="color:#000000"
          d="m-20 80.938c-30.074 0-54.281 24.202-54.281 54.282v41.78l399.28 244c23.35 14.27 60.93 14.27 84.28 0l379.28-231.78v-54c0-30.08-24.2-54.282-54.28-54.282h-754.28z"
          :stroke="borders_color"
          stroke-linecap="round"
          stroke-width="6.3908"
          fill="url(#linearGradient2224)"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props:{
    principal_color:{
      type: String,
      default: "#00a6f8"
    },
    secundary_color:{
      type: String,
      default: "#12346b"
    },
    borders_color:{
      type: String,
      default: "#12346b"
    }
  }
}
</script>

<style lang="css">
</style>
