<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32">
    <path
      :fill="color"
      d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM13.52 23.383l-7.362-7.363 2.828-2.828 4.533 4.535 9.617-9.617 2.828 2.828-12.444 12.445z">
    </path>
  </svg>
</template>

<script>
export default {
  props:{
    'color':{
      type: String,
      default: "#4CAF50"
    }
  }
}
</script>
