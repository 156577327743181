<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="500.000000pt"
    height="500.000000pt"
    viewBox="0 0 500.000000 500.000000"
    preserveAspectRatio="xMidYMid meet">
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      :fill="color"
      stroke="none">
      <path d="M1587 4646 c-89 -24 -180 -109 -209 -194 -9 -27 -13 -192 -16 -634
      -1 -329 -4 -598 -6 -598 -2 0 -42 18 -89 40 -48 22 -105 43 -127 46 -141 23
      -264 -112 -229 -251 19 -75 63 -114 265 -236 l184 -110 0 -55 c0 -53 -1 -54
      -24 -48 -38 9 -88 -12 -127 -54 -51 -53 -68 -85 -80 -145 -12 -65 -4 -117 26
      -161 l23 -34 -33 -32 c-84 -82 -108 -245 -46 -321 19 -23 19 -24 -25 -72 -48
      -54 -64 -96 -64 -175 0 -60 27 -123 63 -146 28 -19 243 -96 267 -96 14 0 19
      -11 24 -47 15 -107 57 -183 129 -231 56 -38 93 -50 185 -58 l77 -7 90 -86
      c102 -98 179 -152 266 -187 50 -20 75 -24 137 -22 l77 3 -85 29 c-127 43 -226
      98 -317 177 -46 40 -83 76 -83 80 0 5 295 9 665 9 524 0 666 -3 669 -12 15
      -54 63 -172 71 -174 6 -2 18 3 28 10 18 14 18 16 -2 54 -58 114 -89 350 -72
      543 20 220 54 321 193 586 52 100 80 165 80 186 0 18 -15 102 -33 187 -29 138
      -33 173 -33 315 0 154 1 163 32 252 37 108 67 159 117 200 58 48 145 66 199
      42 39 -18 53 -72 66 -245 16 -224 46 -449 69 -515 22 -65 40 -102 80 -161 59
      -89 112 -262 131 -428 15 -129 3 -249 -45 -440 -33 -134 -42 -324 -20 -410 32
      -126 33 -130 67 -130 25 0 29 3 23 18 -4 9 -15 50 -26 91 -31 120 -26 239 18
      438 34 155 37 180 37 333 0 136 -3 180 -21 250 -34 134 -82 257 -127 325 -40
      59 -68 120 -80 175 -12 57 -37 277 -51 453 -8 103 -21 202 -29 220 -18 43 -66
      83 -111 91 -51 10 -134 -10 -188 -44 l-46 -29 -3 607 c-3 596 -3 608 -24 653
      -31 67 -87 124 -152 156 l-57 28 -815 2 c-646 2 -825 -1 -863 -11z m1691 -127
      c26 -13 54 -38 70 -62 l27 -41 1 -715 c1 -408 -3 -735 -8 -761 -35 -169 -29
      -322 24 -569 24 -118 28 -152 20 -175 -6 -16 -48 -95 -92 -177 -139 -254 -174
      -391 -175 -681 l0 -188 -755 1 c-560 0 -764 4 -788 13 -46 16 -100 72 -112
      115 -20 72 -13 133 25 205 39 75 46 141 21 201 -14 34 -14 34 20 54 38 23 89
      96 104 151 14 51 12 125 -5 166 -14 35 -14 36 19 67 65 62 99 170 82 261 -12
      62 -43 88 -165 131 l-111 40 2 931 3 930 27 41 c15 23 44 49 70 62 43 21 45
      21 847 21 800 0 805 0 849 -21z m-2021 -1316 l103 -48 0 -187 c0 -104 -3 -188
      -7 -188 -5 0 -84 47 -178 104 -139 84 -174 110 -192 142 -60 103 9 224 127
      224 33 0 73 -13 147 -47z m248 -716 c167 -61 170 -63 189 -101 17 -35 18 -46
      8 -95 -15 -68 -53 -127 -96 -148 -33 -15 -36 -15 -207 46 -120 43 -181 70
      -196 87 -36 39 -29 110 20 213 14 28 63 61 92 61 11 -1 97 -29 190 -63z m-86
      -363 c86 -31 164 -63 173 -71 26 -23 32 -85 14 -147 -20 -66 -80 -136 -117
      -136 -13 0 -96 27 -184 58 -175 64 -185 72 -185 147 0 68 20 117 65 162 23 24
      50 43 60 43 9 0 88 -25 174 -56z m-80 -367 c137 -50 154 -68 153 -151 -1 -88
      -51 -162 -123 -179 -18 -5 -63 7 -144 36 -133 48 -154 66 -162 141 -10 87 66
      195 137 196 14 0 76 -19 139 -43z"/>
      <path d="M2387 4462 c-37 -40 -9 -102 46 -102 33 0 57 24 57 59 0 54 -68 82
      -103 43z"/>
      <path d="M1820 4228 c-87 -25 -118 -72 -127 -194 l-6 -84 32 0 31 0 0 73 c0
      39 6 83 13 97 18 37 72 60 140 60 53 0 57 2 57 24 0 19 -6 24 -37 30 -47 7
      -59 7 -103 -6z"/>
      <path d="M2900 4206 c0 -25 2 -26 58 -26 67 0 121 -23 139 -60 7 -14 13 -58
      13 -97 l0 -73 31 0 32 0 -5 83 c-9 146 -51 190 -185 196 l-83 3 0 -26z"/>
      <path d="M2281 4074 c-81 -31 -85 -34 -117 -102 -23 -49 -26 -68 -27 -165 0
      -95 -3 -113 -20 -130 -21 -21 -19 -51 11 -142 13 -37 23 -51 38 -53 12 -2 26
      -13 31 -25 6 -12 27 -43 47 -70 20 -26 36 -56 36 -66 0 -37 -48 -79 -108 -96
      -83 -24 -188 -82 -235 -130 -70 -71 -101 -201 -57 -245 19 -19 33 -20 550 -20
      527 0 530 0 551 21 19 19 21 30 16 85 -6 75 -38 138 -94 182 -50 39 -145 86
      -216 107 -59 17 -107 60 -107 96 0 10 16 40 36 66 20 27 41 58 47 70 5 12 19
      23 31 25 16 2 26 18 43 68 28 83 28 105 2 131 -18 18 -20 28 -14 77 8 76 -9
      150 -43 183 -15 14 -35 40 -44 57 -22 42 -68 68 -150 87 -91 20 -132 18 -207
      -11z"/>
      <path d="M1693 2924 c-3 -9 -3 -47 0 -85 11 -125 58 -173 183 -184 41 -4 77
      -6 78 -4 2 2 6 15 8 29 3 24 1 25 -75 31 -67 4 -81 9 -105 32 -24 25 -27 36
      -31 112 -3 82 -4 85 -27 85 -14 0 -27 -7 -31 -16z"/>
      <path d="M3109 2856 c-4 -78 -7 -88 -32 -113 -24 -24 -36 -28 -102 -31 l-75
      -4 0 -30 0 -31 78 7 c87 8 127 27 159 75 23 34 37 111 31 171 -2 29 -7 35 -29
      38 l-27 3 -3 -85z"/>
      <path d="M2252 1539 c-90 -58 -80 -192 18 -241 45 -23 263 -26 313 -4 75 32
      110 123 73 195 -32 61 -63 71 -226 71 -130 0 -148 -2 -178 -21z m318 -42 c53
      -27 62 -96 18 -137 -20 -18 -35 -20 -159 -20 l-137 0 -27 32 c-21 25 -25 38
      -21 61 7 36 23 56 51 67 33 14 247 11 275 -3z"/>
    </g>
  </svg>
</template>
<script>
export default {
  props:{
    color: {
      type: String,
      default: "#BDBDBD"
    }
  }
}
</script>
