<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 551.6 137.49">
    <g>
      <g>
        <path
          id="Path_953"
          data-name="Path 953"
          :fill="second_color"
          d="m60.15,33.46v11.47h-4.94c-4.94,0-7.41,3.2-7.41,9.73v3.63h9.58v9.58h-9.73v36.14h-12.05v-36.14h-8.71v-9.58h8.71v-3.63c0-13.36,6.83-21.2,19.46-21.2h5.09Z"/>
        <path
          id="Path_954"
          data-name="Path 954"
          :fill="second_color"
          d="m95.88,57.12h11.91v46.04h-11.91v-6.54c-2.6,4.53-7.41,7.35-12.63,7.41-11.04,0-18.01-7.13-18.01-19.75v-26.87h12.05v24.84c0,6.97,3.92,10.18,8.71,10.18,5.23.26,9.69-3.77,9.95-9,.03-.58,0-1.16-.07-1.74v-24.56Z"/>
        <path
          id="Path_955"
          data-name="Path 955"
          :fill="second_color"
          d="m186.79,93.08c-5.78,2.31-12.36-.22-15.1-5.81l33.69-10.18c-.25-1.38-.59-2.74-1.02-4.07-1.66-6.25-5.81-11.55-11.47-14.67-5.49-2.95-11.97-3.47-17.86-1.45-6.06,1.73-11.22,5.74-14.38,11.2-3.15,5.69-3.74,12.45-1.6,18.59,3.2,12.37,15.82,19.79,28.19,16.59.53-.14,1.06-.29,1.58-.47,7.61-2.08,13.8-7.62,16.7-14.96h-11.31c-1.61,2.71-4.31,4.62-7.41,5.23h-.02Zm-17.43-13.65c-.87-5.96,2.99-11.59,8.86-12.92,5.5-2.02,11.62.58,13.96,5.95l-22.82,6.97Z"/>
        <path
          id="Path_956"
          data-name="Path 956"
          :fill="second_color"
          d="m129.43,83.25c-12.05-3.49-13.36-8.71-13.36-14.38.05-3.87,1.94-7.49,5.09-9.73,3.45-2.8,7.78-4.29,12.22-4.21,4.49-.16,8.91,1.12,12.63,3.63,3.64,2.56,5.76,6.76,5.67,11.2h-11.5c-.15-3.2-3.2-4.8-6.39-4.8-3.92,0-5.96,1.45-5.96,4.65,0,1.74,1.6,3.05,4.94,3.92l7.84,1.89c7.01,1.03,12.24,7,12.34,14.08.11,4.36-2.1,8.44-5.81,10.74-4.03,2.57-8.73,3.88-13.5,3.78-4.75.06-9.39-1.42-13.23-4.21-3.65-2.74-5.8-7.04-5.79-11.61h11.47c1,3.89,4.73,6.43,8.71,5.94,3.49-.15,6.11-2.04,6.11-4.8.15-2.32-1.45-3.63-5.09-4.5l-6.39-1.6v.02Z"/>
      </g>
      <g>
        <path
          :fill="first_color"
          d="m258.04,102.84h-5.74l1.91-9.93h-.18c-4.37,6.83-11.84,11.12-20.96,11.12-10.84,0-19.68-7.38-19.68-19.32,0-15.85,12.66-28.06,28.88-28.06,12.12,0,20.59,7.84,20.59,19.59,0,1.37-.09,2.92-.36,4.37l-4.46,22.23Zm-1.55-25.78c0-8.66-5.28-14.85-14.67-14.85-12.3,0-22.05,9.48-22.05,21.41,0,8.66,5.28,14.85,14.67,14.85,12.3,0,22.05-9.48,22.05-21.41Z"/>
        <path
          :fill="first_color"
          d="m312.15,73.41c-1.18-6.74-6.65-11.21-14.85-11.21-12.3,0-22.05,9.48-22.05,21.41,0,8.66,5.28,14.85,14.67,14.85,8.29,0,15.12-4.46,18.86-11.21h6.65c-4.65,10.02-14.12,16.77-25.97,16.77s-20.59-8.2-20.59-19.59c0-15.58,12.66-27.79,28.88-27.79,11.57,0,19.68,6.74,20.32,16.76h-5.92Z"/>
        <path
          :fill="first_color"
          d="m365,73.41c-1.18-6.74-6.65-11.21-14.85-11.21-12.3,0-22.05,9.48-22.05,21.41,0,8.66,5.28,14.85,14.67,14.85,8.29,0,15.12-4.46,18.86-11.21h6.65c-4.65,10.02-14.12,16.77-25.97,16.77s-20.59-8.2-20.59-19.59c0-15.58,12.66-27.79,28.88-27.79,11.57,0,19.68,6.74,20.32,16.76h-5.92Z"/>
        <path
          :fill="first_color"
          d="m422.94,82.34h-42.09v1.46c0,8.56,5.47,14.67,14.94,14.67,7.2,0,13.03-2.92,16.95-8.2h6.83c-5.1,8.29-13.85,13.76-24.42,13.76-12.3,0-20.59-8.2-20.59-19.59,0-15.58,12.76-27.79,28.88-27.79,12.39,0,20.41,7.93,20.41,18.95,0,1.55-.18,3.1-.46,4.74l-.46,2Zm-41-5.56v.18h35.9v-.55c0-8.47-5.56-14.21-15.03-14.21s-18.04,5.92-20.86,14.58Z"/>
        <path
          :fill="first_color"
          d="m460.48,70.13c.09-.46.09-.91.09-1.37,0-4.28-3.37-6.65-9.48-6.65-8.02,0-13.12,3.64-13.12,9.38,0,9.75,26.15,1.37,26.15,16.31,0,9.2-8.29,16.22-20.86,16.22-10.11,0-16.4-4.92-16.4-11.84,0-.64.09-1.37.18-2h6.2c-.09.46-.09.91-.09,1.28,0,4.56,3.55,7.11,10.39,7.11,9.11,0,14.4-4.01,14.4-9.66,0-9.75-26.15-1.28-26.15-16.31,0-9.11,7.74-15.94,19.68-15.94,9.48,0,15.31,4.83,15.31,11.48,0,.64-.09,1.37-.18,2h-6.1Z"/>
        <path 
          :fill="first_color"
          d="m503.11,70.13c.09-.46.09-.91.09-1.37,0-4.28-3.37-6.65-9.48-6.65-8.02,0-13.12,3.64-13.12,9.38,0,9.75,26.15,1.37,26.15,16.31,0,9.2-8.29,16.22-20.86,16.22-10.11,0-16.4-4.92-16.4-11.84,0-.64.09-1.37.18-2h6.2c-.09.46-.09.91-.09,1.28,0,4.56,3.55,7.11,10.39,7.11,9.11,0,14.4-4.01,14.4-9.66,0-9.75-26.15-1.28-26.15-16.31,0-9.11,7.74-15.94,19.68-15.94,9.48,0,15.31,4.83,15.31,11.48,0,.64-.09,1.37-.18,2h-6.1Z"/>
      </g>
    </g>
    <path
      id="Path_948"
      data-name="Path 948"
      :fill="second_color"
      d="m519.18,47.49c-3.05,0-5.53,2.47-5.53,5.53,0,3.05,2.47,5.53,5.53,5.53,3.05,0,5.53-2.47,5.53-5.53h0c0-3.05-2.47-5.53-5.53-5.53Zm3.15,5.53c-.04.4-.39.69-.79.66h-.13c-.42-.06-.72-.44-.67-.87,0-.02,0-.04,0-.05.11-.45.02-.94-.26-1.31-.27-.31-.23-.79.08-1.06.31-.27.79-.23,1.06.08.02.02.03.04.04.06.52.72.75,1.61.65,2.49h0Z"/>
  </svg>
</template>

<script>
export default {
  props:{
    first_color:{
      type: String,
      default: "#18b7ea"
    },
    second_color:{
      type: String,
      default: "#233f73"
    }
  }
}
</script>

<style lang="css">
</style>
