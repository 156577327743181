<template>
  <span class="spin_loader"></span>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>
