<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24">
    <path
      d="M12 4c-4.419 0-8 3.582-8 8s3.581 8 8 8 8-3.582 8-8-3.581-8-8-8zM15.707 14.293c0.391 0.391 0.391 1.023 0 1.414-0.195 0.195-0.451 0.293-0.707 0.293s-0.512-0.098-0.707-0.293l-2.293-2.293-2.293 2.293c-0.195 0.195-0.451 0.293-0.707 0.293s-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023 0-1.414l2.293-2.293-2.293-2.293c-0.391-0.391-0.391-1.023 0-1.414s1.023-0.391 1.414 0l2.293 2.293 2.293-2.293c0.391-0.391 1.023-0.391 1.414 0s0.391 1.023 0 1.414l-2.293 2.293 2.293 2.293z"
      :fill="color">
    </path>
  </svg>
</template>

<script>
export default {
  props:{
    color:{
      type: String,
      default: "#BDBDBD"
    }
  }
}
</script>

<style lang="css">
</style>
