<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="48"
    viewBox="0 -960 960 960"
    width="48">
    <path
      d="m784-251-43-43q17-28 28-66t11-81q0-125-87.5-212.5T480-741q-42 0-80.5 10.5T333-702l-44-44q42-27 91.5-41t99.5-14q68 0 126.5 22.5T711-716l51-51 42 42-51 51q45 49 66 108.5T840-441q0 50-13.5 98.5T784-251ZM510-525v-118h-60v58l60 60ZM808-56 704-160q-48 38-103.5 58.5T480-81q-74 0-139.5-28.5T226-187q-49-49-77.5-114.5T120-441q0-61 18.5-116T199-665L57-807l43-43L851-99l-43 43Zm-328-85q52 0 98.5-16t85.5-47L243-625q-31 38-47 86t-16 98q0 125 87.5 212.5T480-141ZM360-860v-60h240v60H360Zm94 445Zm83-83Z"
      :fill="color"/>
  </svg>
</template>

<script>
export default {
  props:{
    color: {
      type: String,
      default: "#C62828"
    }
  }
}
</script>

<style lang="css">
</style>
